<template>
  <section class="section container">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>
    <div class="columns is-centered box">
      <div class="column is-5">
        <form v-on:submit.prevent="update">
          <div class="img">
            <img v-if="img[1]" :src="img[1]" alt="Imagem upada" />
            <img v-else src="@/assets/img/perfil-30.png" alt="Img perfil" />

            <b-upload v-model="img[0]" @input="upload($event)">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Adicionar Foto</span>
              </a>
            </b-upload>
          </div>

          <b-field label="NOME">
            <b-input type="text" v-model="user.name" required></b-input>
          </b-field>

          <div class="div is-flex-desktop">
            <b-field label="CPF" class="m-r-md" :type="erroForm.cpf">
              <b-input v-mask="['###.###.###-##']" v-model="user.cpf" disabled required></b-input>
            </b-field>

            <b-field label="Data de Nascimento">
              <b-datepicker
                :month-names="datePicker[0]"
                :day-names="datePicker[1]"
                icon="calendar-alt"
                v-model="user.birthday"
              ></b-datepicker>
            </b-field>
          </div>

          <b-field label="CEP" :type="erroForm.cep">
            <b-input type="text" v-mask="['#####-###']" v-model="cep" maxlength="9" required></b-input>
          </b-field>

          <div class="is-flex-desktop">
            <b-field label="ESTADO" class="m-r-md" v-if="user.state">
              <b-input type="text" v-model="user.state" required></b-input>
            </b-field>

            <b-field label="CIDADE" v-if="user.city">
              <b-input type="text" v-model="user.city" required></b-input>
            </b-field>
          </div>

          <b-field label="ENDEREÇO" v-if="user.city">
            <b-input type="text" v-model="user.address" required></b-input>
          </b-field>

          <b-field label="BAIRRO" v-if="user.city">
            <b-input type="text" v-model="user.neighborhood" required></b-input>
          </b-field>

          <b-field label="NÚMERO" v-if="user.city">
            <b-input type="number" v-model="user.addressNumber" required></b-input>
          </b-field>

          <div class="is-flex-desktop">
            <b-field label="TELEFONE">
              <b-input
                class="m-r-md"
                type="text"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="user.phone"
              ></b-input>
            </b-field>

            <b-field label="CELULAR">
              <b-input
                type="text"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="user.cellPhone"
                required
              ></b-input>
            </b-field>
          </div>

          <b-field label="Time Zone">
            <b-select placeholder="Select a name" v-model="user.timeZone">
              <option
                v-for="option in timeZone"
                :value="option.id"
                :key="option.id"
              >{{ option.name }}</option>
            </b-select>
          </b-field>

          <b-field label="Email" :type="erroForm.email">
            <b-input type="email" v-model="user.email" required></b-input>
          </b-field>

          <b-field label="Senha">
            <b-input type="password" v-model="user.password"></b-input>
          </b-field>

          <!-- <b-field label="Senha">
            <b-input type="password" v-model="user.password" required></b-input>
          </b-field>-->

          <button class="m-t-sm button is-info is-fullwidth">Salvar</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

import { mask } from "vue-the-mask";
import { validateCPF } from "../../../assets/js/validatorCpf";
export default {
  directives: { mask },
  data() {
    return {
      img: [],
      erroForm: {
        cep: "",
        cpf: "",
        email: ""
      },
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ],
      user: [],
      cep: "",
      isLoading: false,
      timeZone: [
        {
          name: "São paulo",
          id: "America/Sao_Paulo"
        },
        {
          name: "Belém",
          id: "America/Belem"
        },
        {
          name: "Fortaleza",
          id: "America/Fortaleza"
        },
        {
          name: "Recife",
          id: "America/Recife"
        },
        {
          name: "Araguaína",
          id: "America/Araguaina"
        },
        {
          name: "Maceió",
          id: "America/Maceio"
        },
        {
          name: "Bahia",
          id: "America/Bahia"
        },
        {
          name: "Campo Grande",
          id: "America/Campo_Grande"
        },
        {
          name: "Cuiabá",
          id: "America/Cuiaba"
        },
        {
          name: "Santarém",
          id: "America/Santarem"
        },
        {
          name: "Porto Velho",
          id: "America/Porto_Velho"
        },
        {
          name: "Boa Vista",
          id: "America/Boa_Vista"
        },
        {
          name: "Manaus",
          id: "America/Manaus"
        },
        {
          name: "Eirunepé",
          id: "America/Eirunepe"
        },
        {
          name: "Rio Branco",
          id: "America/Rio_Branco"
        }
      ]
    };
  },
  methods: {
    toNumber(data) {
      return data.replace(/[^\d]+/g, "");
    },
    update() {
      this.erroForm = {
        cep: "",
        cpf: "",
        email: ""
      };
      this.user.cpf = this.toNumber(this.user.cpf);
      if (validateCPF(this.user.cpf)) {
        this.user.cellPhone
          ? (this.user.cellPhone = this.toNumber(this.user.cellPhone))
          : "";

        this.user.phone
          ? (this.user.phone = this.toNumber(this.user.phone))
          : "";

        delete this.user.createdAt;
        delete this.user.updatedAt;
        delete this.user.roles;

        if(this.user.birthday) this.user.birthday = new Date(this.user.birthday);

        if (!this.user.password) delete this.user.password;

        this.user.addressNumber = this.user.addressNumber * 1;

        Axios.put(`/v1/users/me`, this.user)
          .then(data => {
            if (data.data) {
              this.$buefy.notification.open({
                message: "Perfil atualizado com sucesso!",
                type: "is-success",
                hasIcon: true
              });
              location.reload();
            }
          })
          .catch(erro => {
            for (const e of erro.response.data.error) {
              if (e.message[0].includes("cpf") && e.message[0].includes("exists")) {
                this.$buefy.dialog.alert("CPF já utlizado!");
                this.erroForm.cpf = "is-danger";
              } else if (
                e.message[0].includes("uid") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              } else if (
                e.message[0].includes("email") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              }
            }
          });
      } else {
        this.erroForm.cpf = "is-danger";
        this.$buefy.dialog.alert("CPF inválido!");
      }
    },
    getCep() {
      var temp = [];
      temp[0] = Axios.defaults.headers.common["Authorization"];
      temp[1] = Axios.defaults.headers.post["Content-Type"];
      temp[2] = Axios.defaults.headers.common["cache-control"];
      delete Axios.defaults.headers.common["Authorization"];
      delete Axios.defaults.headers.post["Content-Type"];
      delete Axios.defaults.headers.common["cache-control"];
      Axios({
        method: "GET",
        url: `https://viacep.com.br/ws/${this.cep}/json/`
      })
        .then(data => {
          var address = data.data;
          this.user.zipCode = this.toNumber(address.cep);
          this.user.address = address.logradouro;
          this.user.city = address.localidade;
          this.user.state = address.uf;
          this.erroForm.cep = null;
          this.user.neighborhood = address.bairro;
          Axios.defaults.headers.common["Authorization"] = temp[0];
          Axios.defaults.headers.post["Content-Type"] = temp[1];
          Axios.defaults.headers.common["cache-control"] = temp[2];
        })
        .catch(erro => {
          console.error(erro);
          this.erroForm.cep = "is-danger";
          this.$buefy.dialog.alert("CEP inválido!");
          Axios.defaults.headers.common["Authorization"] = temp[0];
          Axios.defaults.headers.post["Content-Type"] = temp[1];
          Axios.defaults.headers.common["cache-control"] = temp[2];
        });
    },
    getPlayer() {
      Axios.get(`/v1/users/me`)
        .then(data => {
          this.user = data.data;
          this.cep = this.user.zipCode;
          this.img[1] = data.data.image;
          if(this.user.birthday) this.user.birthday = new Date(this.user.birthday);
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    upload(data) {
      this.isLoading = true;
      const fd = new FormData();
      fd.append("file", data, data.name);
      Axios.post(`/v1/uploads/`, fd)
        .then(res => {
          this.img[1] = res.data.location;
          this.user.image = res.data.location;
          this.$forceUpdate(this.img);
          this.isLoading = false;
        })
        // eslint-disable-next-line
        .catch(err => {
          this.$buefy.dialog.alert("Erro ao enviar arquivo!");
          this.isLoading = false;
        });
    }
  },
  watch: {
    cep(cep) {
      if (cep.length == 9) {
        this.getCep();
      }
    }
  },
  mounted() {
    this.getPlayer();
  }
};
// ${this.$store.state.user.user._id}
</script>
<style lang="scss" scoped>
.img {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;

  img {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: block;
    margin-bottom: 10px;
    border: #ccc solid 3px;
  }
}
</style>
